import 'slick-carousel/slick/slick.min';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';

$(document).ready(function(){
  $('.custom-carousel').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 300,
    arrows: true,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
});
